<template>
  <div>
    <v-card v-if="!getPoisLoaded || loadsTour">
      <v-skeleton-loader type="card"></v-skeleton-loader>
    </v-card>
    <v-card v-if="getPoisLoaded && !loadsTour && downloadedTour">
      <v-card-title>Tour "{{ downloadedTour.name }}"</v-card-title>
      <v-card-actions>
        <v-btn @click="addTour()">Hinzufügen</v-btn>
        <v-btn @click="cancel()">Abbrechen</v-btn>
      </v-card-actions>
      <!-- {{ downloadedTour }} -->
    </v-card>
    <v-card v-if="getPoisLoaded && !loadsTour && !downloadedTour">
      <v-card-title
        >Tour mit Id "{{ tourId }} konnte nicht geladen werden."</v-card-title
      >
    </v-card>
  </div>
</template>
<script>
import { backButtonPageMixin } from "../mixins/backButtonPageMixin";
import { mapGetters, mapActions } from "vuex";
import { GET_POIS_LOADED } from "../store/modules/poistore";
import {
  GET_TOUR_BY_EXTERN_ID,
  GET_API_SERVICE,
} from "../store/modules/userstore";
export default {
  name: "TourDownload",
  mixins: [backButtonPageMixin],
  data() {
    return {
      downloadedTour: null,
      loadsTour: false,
      success: false,
    };
  },
  props: {
    tourId: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      getApiClient: "user/" + GET_API_SERVICE,
      getTourByExternId: "user/" + GET_TOUR_BY_EXTERN_ID,
      getPoisLoaded: "pois/" + GET_POIS_LOADED,
    }),
  },
  methods: {
    ...mapActions({
      addDownloadedTour: "user/addDownloadedTour",
    }),
    addTour() {
      const externalTourId = this.downloadedTour.id;
      this.addDownloadedTour(this.downloadedTour);
      console.log("DownloadedTour " + this.downloadedTour.id);
      const tour = this.getTourByExternId(externalTourId);
      console.log(this.tour);
      this.$router.push({ path: `/tour/${tour.id}` });
    },
    cancel() {
      this.$router.push({ path: "/" });
    },
  },
  async mounted() {
    const client = this.getApiClient;
    try {
      this.loadsTour = true;
      const tour = await client.getTour(this.tourId);
      if (tour.data && tour.data.id && tour.data.poiIds && tour.data.name) {
        this.downloadedTour = tour.data;
        console.log(this.downloadedTour);
        this.success = true;
      } else {
        this.success = false;
        console.log("not successful");
      }
    } catch (e) {
      console.log(e);
    }
    this.loadsTour = false;
    // in case we already have that tour redirect to page
    if (this.success) {
      const tour = this.getTourByExternId(this.downloadedTour.id);
      if (tour) {
        this.$router.push({ path: `/tour/${tour.id}` });
      }
    }
  },
};
</script>
